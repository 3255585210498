<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          {{
            $route.params.id == 0
              ? "Nueva notificación"
              : "Notificación: " + $route.params.id
          }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12">
              <CInput
                label="Titulo"
                placeholder="Titulo"
                v-model="form.title"
                required
              /> </CCol
          >
          <CSelect label="Rol" aria-label="Rol" v-model="form.role" :options="role">
            <template #prepend-content
              ><font-awesome-icon icon="user-tag"
            /></template>
          </CSelect>
          </CRow>
          
          <CRow>
            <CCol sm="12">
              <CTextarea
                label="Mensaje"
                placeholder="Mensaje"
                vertical
                rows="9"
                v-model="form.message"
                required
              /> </CCol
          ></CRow>
        </CCardBody>
        <CCardFooter>
          <CRow class="mt-2">
            <CCol sm="12" md="6">
              <CButton color="primary" @click="goBack">Back</CButton>
            </CCol>
            <CCol sm="12" md="6" class="text-right" v-if = "nuevo">
              <CButton color="primary" @click = "save" v-if = "!loading"
                >Guardar</CButton
              >
              <div class="text-right" v-if="loading">
                <CSpinner variant="grow" size="lg" />
              </div>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>

    <CToaster :autohide="3000">
      <template v-for="(item, index) in toasts">
        <CToast
          :key="`${item.title}-${index}`"
          :show="true"
          :color="item.color"
          :header="item.title"
        >
          {{ item.message }}
        </CToast>
      </template>
    </CToaster>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";
import moment from "moment";

const resource = new Resource("notifications");

export default {
  name: "Notification",
  data() {
    return {
      loading: false,
      toasts: [],
      opened: null,
      form: {
        title: null,
        role: null,
        message: null,
      },
      nuevo: true,
      role: [
        "Administrador",
        "Vendedor BSmart",
        "Mayorista",
        "Mayorista Vendedor",
        "Distribuidor",
        "Subdistribuidor",
        "Financiero",
        "Soporte",
      ],
    };
  },
  mounted() {
    if (this.$route.params.id != 0) {
      this.getItem();
      this.nuevo = false;
    }else{
      this.form.role = 'Administrador';
      this.nuevo = true;
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async save() {
      this.loading = true;
      try {
        if (this.$route.params.id == 0) {
          const response = await resource.store(this.form);
          if (response.status == 200) {
            const { created_at, updated_at, ...data } = response.data.data;
            this.form = data;
            this.toasts.push({
              title: "Confirmación",
              color: "success",
              message: "Notificación creada con exito",
            });
          } else {
            throw response.data.message;
          }
          this.goBack();
        } else {
          const response = await resource.update(
            this.$route.params.id,
            this.form
          );
          if (response.status == 200) {
            const { created_at, updated_at, ...data } = response.data.data;
            this.form = data;
            this.toasts.push({
              title: "Confirmación",
              color: "success",
              message: "Notificación actualizada con exito",
            });
          } else {
            throw response.data.message;
          }
        }
      } catch (error) {
        console.log(error);
        this.toasts.push({
          title: "Error",
          color: "danger",
          message: error,
        });
      } finally {
        this.loading = false;
      }
    },

    async getItem() {
      this.loading = true;
      try {
        const response = await resource.get(this.$route.params.id);
        if (response.status == 200) {
          const { created_at, updated_at, ...data } = response.data.data;
          this.form = data;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
